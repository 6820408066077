@font-face {
  font-family: Pinery;
  src:
    url(./assets/fonts/PineryRZSBlack-null.otf)
    format("opentype");
  src: url(./assets/fonts/PineryRZSBlack-null.woff)
    format("woff");
}

@font-face {
  font-family: PineryBlack;
  src:
    url("./assets/fonts/PineryRZSBlack-null.otf")
    format("opentype");
  src: url(./assets/fonts/PineryRZS-Black.woff)
    format("woff");
}

@font-face { 
  font-family: UniversLight;
  src:
    url(./assets/fonts/Univers-Light.otf)
    format("opentype");
  src: url(./assets/fonts/Univers-Light_woff)
    format("woff");
}

@font-face {
  font-family: UniversLightOblique;
  src:
    url(./assets/fonts/Univers-LightOblique.otf)
    format("opentype");
  src: url(./assets/fonts/Univers-Light_woff)
    format("woff");
}

*, button {
  outline: none;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  background-color: #000;
}

body {
  background-color: #000;
  font-family: Helvetica Neue;
  overflow: hidden;
}

a, a:hover {
  color: #000;
  text-decoration: none
}

.slick-list> div {
margin-left:0;
}

.slick-prev,
.slick-next {
  display: none;
  opacity: 0;
  height: 0;
  width: 0;
}

.slick-loading,
.slick-loading .slick-list {
  display: none !important;
}

.video-raw {
  width: initial;
  height: initial;
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  transform: translateX(calc((100% - 100vw) / 2));
}

@media screen and (max-aspect-ratio: 1920/1080) {
  .video-raw {
    height: 100%;
  }
}

@media (orientation: landscape) {
  .video-raw {
    width: initial;
    height: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: initial;
    min-height: initial;
    transform: translateX(calc((100% - 100vw) / 2));
  }
}

.slick-slide {
  position: relative;
}